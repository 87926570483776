<template>
    <ui-create
        name="grid"
        :title="title"
        :load="load"
    >
        <template #body >
            <v-form ref="form" >
                <v-row>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-text-field
                            label="Descrição"
                            v-model="form.descricao"
                            counter
                            maxlength="64"
                            :rules="[
                                form_descricao_rules.required, form_descricao_rules.counter,
                                    form_descricao_rules.min
                            ]"
                        ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-autocomplete
                            label="Produto"
                            v-model="form.id_produto"
                            :items="sectionsProduct"
                            item-text="descricao"
                            item-value="id"
                            :rules="[form_produto_rules.required]"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
export default {
    name: 'vwgroupCreate',
    components: {
        uiCreate
    },
    data(){
        return {
            load: false,
            form: {
                descricao: '',
                id_produto: null,
            },

            sectionsProduct: ['1', '2', '3'],

            form_descricao_rules: {
                required: value => !!value || 'Descrição é obrigatória.',
                counter: value => value.length <= 64 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },

            form_produto_rules: {
                required: value => !!value || 'Selecione um produto.'
            }
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id
        },

        title(){
            return this.id ? `Editar Grade: ${this.id}` : 'Cadastrar Grade'
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },
    
        save: function(){
                this.overlay = true
                if(this.id !== undefined){
                    this.update()
                }else{
                    this.create()
                }
        },

        update: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/grids/update/' + this.id, {
                method: 'PUT',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(() => {
                    this.$root.$emit('open-snackbar-on-grids', 'Cadastro atualizado!')
                    this.returnRead()
                })
                .catch(error => {
                    console.error(error)
                    this.$root.$emit('open-snackbar-on-grids', 'Erro interno ao atualizar a Grade!')
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        create: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/grids/create', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.$root.$emit('open-snackbar-on-grids', 'Cadastro atualizado!')
                    this.clearForm()
                })
                .catch(error => {
                    console.error(error)
                    this.$root.$emit('open-snackbar-on-grids', 'Erro interno ao atualizar a Grade!')
                })
        },

        getData: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/grids/read/' + this.id, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.form = resJSON.response[0].listhelper[0]
                })
                .catch(() => {
                    this.$root.$emit('open-snackbar-on-grids', 'Erro interno ao atualizar a Grade!')
                })
        },

        getSections: function(){
            fetch(process.env.VUE_APP_API + '/generics/description/produtos', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.sectionsProduct = resJSON.response
                })
                .catch(() => {
                    this.$root.$emit('open-snackbar-on-grids', 'Erro interno ao atualizar a Grade!')
                })
        }
    },

    mounted(){
        if (this.id != undefined) {
            this.getData()
        }

        this.getSections()

        this.$root.$on('save-changes-on-grid', () => {
            this.$nextTick(() => {
                this.save()
            })
        })
    }
}
</script>